<template>
  <div>
    <h1>Fragebogen <span v-html="Formular.Name" /></h1>
    <p>Deine hier eingegebenen Daten werden wir unseren Mitgliedern und der Öffentlichkeit  unter anderem auf unserer Website sowie auf SocialMedia präsentieren. Durch das Ausfüllen bzw. Absenden des Fragebogens erklärst du dich hiermit einverstanden.</p>
    <h2>Deine Daten</h2>
    <label for="vorname">Vorname</label>
    <input id="vorname" type="text" v-model="Daten.Vorname" disabled />
    <label for="nachname">Nachname</label>
    <input id="nachname" type="text" v-model="Daten.Nachname" disabled />
    <label for="foto">Foto</label>
    <input id="foto" v-if="Daten.Foto === null" type="file" accept="image/*" v-on:change="AuswahlFoto" />
    <img id="FotoVorschau" v-if="Daten.Foto" :src="Daten.Foto"/>
    <button id="FotoEntfernen" v-if="Daten.Foto" v-on:click="Daten.Foto = null; Daten.FotoKlein = null;">Foto entfernen</button>
    <label for="mail">Kontakt-E-Mail (werden wir nicht veröffentlichen)</label>
    <input id="mail" type="email" v-model="Daten.Mail" />
    <label for="kreisverband">FDP-Kreisverband</label>
    <select id="kreisverband" v-model="Daten.Kreisverband">
      <option value="" disabled>--- bitte auswählen ---</option>
      <option>KV Bautzen</option>
      <option>KV Chemnitz</option>
      <option>KV Dresden</option>
      <option>KV Erzgebirge</option>
      <option>KV Görlitz</option>
      <option>KV Leipzig</option>
      <option>KV Leipziger Land/Muldental</option>
      <option>KV Meißen</option>
      <option>KV Mittelsachsen</option>
      <option>KV Nordsachsen</option>
      <option>KV Sächsische Schweiz-Osterzgebirge</option>
      <option>KV Vogtland</option>
      <option>KV Zwickau</option>
    </select>
    <label for="kandidatur">Ich möchte kandidieren für...</label>
    <input id="kandidatur" type="text" v-model="Daten.Kandidatur" placeholder="z.B. Listenplatz 45, Direktwahlkreis 199" />
    <label>Bist du Mitglied oder Fördermitglied der JuliA? (<a href="http://sachsen.multisite.julis.de/wp-content/uploads/sites/128/2023/09/Foerdermitgliedschaftsantrag.pdf" target="_blank">Hier</a> kannst du uns unterstützen und Fördermitglied werden. Wenn du Mitglied werden möchtest wirst du <a href="https://julis.de/mitglied-werden/" target="_blank">hier</a> fündig.)</label>
    <div class="FM">
      <div class="buttonJa" :class="{active: Daten.Mitgliedschaft === 2}" v-on:click="Daten.Mitgliedschaft = 2;">Mitglied</div>
      <div class="buttonNeutral" :class="{active: Daten.Mitgliedschaft === 1}" v-on:click="Daten.Mitgliedschaft = 1;">Fördermitglied</div>
      <div class="buttonNein" :class="{active: Daten.Mitgliedschaft === 0}" v-on:click="Daten.Mitgliedschaft = 0;">Nein</div>
    </div>
    <h2>Unsere Fragen</h2>
    <div class="statementblock" v-for="(statement, nummer) in Statements" :key="statement.id">
      <p class="statement">{{nummer + 1}}. <span v-html="statement.Statement" /></p>
      <div class="meinung">
        <div class="buttonJa" :class="{active: statement.Meinung > 0}" v-on:click="statement.Meinung = 1;">Zustimmung</div>
        <div class="buttonNeutral" :class="{active: statement.Meinung == 0}" v-on:click="statement.Meinung = 0;">neutral</div>
        <div class="buttonNein" :class="{active: statement.Meinung < 0}" v-on:click="statement.Meinung = -1;">Ablehnung</div>
      </div>
      <textarea class="erklaerung" rows="3" :maxlength="Formular.Erklaerungslaenge" placeholder="Begründung" v-model="statement.Antwort"></textarea>
      <p v-if="Formular.Erklaerungslaenge" class="zeichen" :class="{inaktiv: statement.Antwort.length == 0, grenze: statement.Antwort.length >= Formular.Erklaerungslaenge - 5}">{{statement.Antwort.length}} von {{Formular.Erklaerungslaenge}}</p>
    </div>
    <button id="absenden" v-on:click="absenden">Absenden</button>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'
import axios from 'axios'

export default {
  name: 'KandidatenFormular',

  mixins: [Konstanten],

  data () {
    return {
      Formular: {
        Name: '',
        Datum: null,
        Erklaerungslaenge: null
      },
      Kandidatencode: null,
      Daten: {
        kId: null,
        Vorname: '',
        Nachname: '',
        Foto: null,
        FotoKlein: null,
        Mail: '',
        Kreisverband: '',
        Kandidatur: '',
        Mitgliedschaft: null,
        abgenommen: 0
      },
      Statements: [
      ],
      abgesendet: false
    }
  },

  computed: {

  },

  methods: {
    kandidatPruefen: function () {
      this.Kandidatencode = this.$route.params.code
      axios.post(this.getFunctionsURL(), {
        function: 'isKandidat',
        code: this.Kandidatencode
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === null || response.data.abgenommen) {
          this.$router.push('/kandidat')
        } else {
          this.Daten = response.data
          this.statementsEmpfangen()
          if (this.Daten.Foto === -1) {
            this.Daten.Foto = this.getLadenQuadrat()
            this.fotoLaden()
          }
        }
      })
    },

    fotoLaden: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getKandidatFoto',
        kId: this.Daten.kId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.Daten.Foto = response.data.Foto
        this.Daten.FotoKlein = response.data.FotoKlein
      })
    },

    formulardatenEmpfangen: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getFormularAktuell'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.Formular.Name = response.data.Wahl
        this.Formular.Datum = new Date(response.data.Wahldatum)
        this.Formular.Erklaerungslaenge = response.data['Erklärungslänge']
      })
    },

    statementsEmpfangen: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getStatementsAktuell',
        kId: this.Daten.kId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        for (const statement in response.data) {
          const stm = response.data[statement]
          this.Statements.push({
            sId: stm.sId,
            Statement: stm.Statement,
            Meinung: stm.Antwort,
            Antwort: stm.Erklärung ? stm.Erklärung : ''
          })
        }
      }).then(() => {
        // Zeilenumbruch in Erklärung verbieten
        for (const element of document.getElementsByClassName('erklaerung')) {
          element.addEventListener('keydown', function (e) {
            if (e.keyCode === 13) {
              e.preventDefault()
            }
          })
        }
      })
    },

    AuswahlFoto: function (event) {
      const Foto = event.target.files[0]

      const reader = new FileReader()
      reader.readAsDataURL(Foto)
      reader.onload = (evt) => {
        this.Daten.Foto = evt.target.result

        const img = new Image()
        img.src = this.Daten.Foto
        img.onload = (el) => {
          const resizeMinSite = 250
          const origHeight = el.target.height
          const origWidth = el.target.width

          const elem = document.createElement('canvas')
          const scaleFactor = resizeMinSite / Math.min(origWidth, origHeight)
          if (origHeight > origWidth) {
            elem.width = resizeMinSite
            elem.height = origHeight * scaleFactor
          } else {
            elem.width = origWidth * scaleFactor
            elem.height = resizeMinSite
          }

          const ctx = elem.getContext('2d')
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height)

          this.Daten.FotoKlein = ctx.canvas.toDataURL('image/jpeg', 0.75)
        }
      }
    },

    personendatenSenden: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'PersonendatenSpeichern',
        kId: this.Daten.kId,
        Foto: this.Daten.Foto,
        FotoKlein: this.Daten.FotoKlein,
        Mail: this.Daten.Mail,
        Kandidatur: this.Daten.Kandidatur,
        Kreisverband: this.Daten.Kreisverband,
        Mitgliedschaft: this.Daten.Mitgliedschaft
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data !== '') {
          console.log(response.data)
        } else {
          this.abgeschlossen()
        }
      })
    },

    antwortenSenden: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'AntwortenSpeichern',
        kId: this.Daten.kId,
        Antworten: this.Statements
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data !== '') {
          console.log(response.data)
        } else {
          this.abgeschlossen()
        }
      })
    },

    absenden: function () {
      if (this.Daten.abgenommen) {
        alert('Du hast den Fragebogen bereits ausgefüllt und kannst deine Antworten nicht mehr selbstständig ändern.')
      } else {
        if (this.Daten.Kandidatur.trim() === '') {
          alert('Bitte gib an, wofür du kandidieren möchtest.')
          return null
        }
        if (this.Daten.Mail === null || this.Daten.Mail.trim() === '') {
          alert('Bitte gib eine E-Mail-Adresse an, damit wir dich bei eventuellen Nachfragen erreichen können.')
          return null
        }
        if (this.Daten.Mitgliedschaft === null) {
          alert('Bitte gib an, ob du Mitglied bzw. Fördermitglied bei uns bist.')
          return null
        }
        this.abgesendet = false
        this.personendatenSenden()
        this.antwortenSenden()
      }
    },

    abgeschlossen: function () {
      if (this.abgesendet) {
        alert('Danke für das Ausfüllen des Fragebogens. Deine Daten wurden erfolgreich gesendet. Wir werden deine Antworten zeitnah veröffentlichen.')
        this.go('Start')
      } else {
        this.abgesendet = true
      }
    }
  },

  mounted: function () {
    this.kandidatPruefen()
    this.formulardatenEmpfangen()
  }
}
</script>

<style scoped>
  input, select {
    width: calc(100% - 0.5em);
    max-width: max(calc(100% / 2 - 0.5em), 500px);
    border: 0;
    padding: 0.25em;
    font-family: Anybody;
    font-size: 1.2em;
    margin-bottom: 0.5em;
    display: block;
    background: var(--white);
  }

  #FotoVorschau {
    width: max(calc(100% / 4), 250px);
    aspect-ratio: 1 / 1;
    object-fit: cover;
    margin-bottom: 0.5em;
    display: block;
    border-radius: 0.5em;
  }

  #FotoEntfernen {
    width: max(calc(100% / 4), 250px);
    margin-bottom: 0.5em;
    display: block;
  }

  .FM {
    width: 100%;
  }

  .statement {
    margin-bottom: 0.5em;
  }

  .meinung {
    width: 100%;
  }

  .buttonJa, .buttonNeutral, .buttonNein {
    float: left;
    text-align: center;
    width: calc(100% / 3);
    font-family: Anybody;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5em;
  }

  .buttonJa:hover, .buttonNeutral:hover, .buttonNein:hover {
    color: var(--magenta);
    box-shadow: inset 0 0 0 0.2em var(--cyan);
  }

  .buttonJa {
    color: var(--softMagenta);
    background: var(--white);
    border-radius: 0.5em 0 0 0.5em;
  }
  .buttonJa.active {
    color: var(--magenta);
    background: var(--white);
    box-shadow: inset 0 0 0 0.2em var(--magenta);
  }

  .buttonNeutral {
    color: var(--gray);
    background: var(--softGray);
  }
  .buttonNeutral.active {
    color: var(--black);
    background: var(--gray);
    box-shadow: inset 0 0 0 0.2em var(--magenta);
  }

  .buttonNein {
    color: var(--white);
    background: var(--softMagenta);
    border-radius: 0 0.5em 0.5em 0;
  }
  .buttonNein.active {
    color: var(--white);
    background: var(--magenta);
    box-shadow: inset 0 0 0 0.2em var(--yellow);
  }

  .erklaerung {
    width: calc(100% - 0.5em);
    border: 0;
    padding: 0.25em;
    font-family: Anybody Light;
  }

  .zeichen {
    margin-top: 0;
    text-align: right;
    font-size: 0.8em;
  }
  .zeichen.inaktiv {
    color: var(--gray);
  }
  .zeichen.grenze {
    color: var(--magenta);
  }

  #absenden {
    font-size: 1.5em;
    width: 100%;
  }
</style>
