import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import ErgebnisView from '../views/Ergebnis.vue'
import KandidatenView from '../views/Kandidaten.vue'
import KandidatenFormular from '../views/KandidatenFormular.vue'
import KandidatenFormularError from '../views/KandidatenFormularError.vue'

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ergebnis/:antworten',
    name: 'ergebnis',
    component: ErgebnisView
  },
  {
    path: '/kandidaten',
    name: 'about',
    component: KandidatenView
  },
  {
    path: '/kandidat',
    name: 'KandidatenFormularError',
    component: KandidatenFormularError
  },
  {
    path: '/kandidat/:code',
    name: 'KandidatenFormular',
    component: KandidatenFormular
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
