<template>
  <div class="home">
    <h1 id="titel">Ergebnis</h1>
    <select id="filter" v-model="Filter">
      <option :value="0">Alle</option>
      <option :value="1">Listenkandidaten</option>
      <option :value="2">Direktkandidaten</option>
    </select>
    <p style="margin-top: 0;">Du kannst dir unter diesem Link dein Ergebnis merken und später erneut ansehen. <a v-if="isSecure()" v-on:click="copyLink" style="cursor: pointer;">In die Zwischenablage kopieren.</a></p>
    <div style="display: inline-block;">
      <ErgebnisKandidat v-for="kandidat in Kandidaten" :key="kandidat.kId" :Vorname="kandidat.Vorname" :Nachname="kandidat.Nachname" :Einigkeit="kandidat.Einigkeit" :Foto="kandidat.Foto" :FotoUrl="kandidat.FotoUrl" />
    </div>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'
import axios from 'axios'
import ErgebnisKandidat from '../components/ErgebnisKandidat'

export default {

  name: 'ErgebnisView',

  mixins: [Konstanten],

  components: {
    ErgebnisKandidat
  },

  data () {
    return {
      Daten: {
        Kandidaten: [],
        Statements: [],
        Antworten: []
      },
      Filter: 0
    }
  },

  computed: {
    Kandidaten: function () {
      let Kandidaten = this.Daten.Kandidaten
      switch (this.Filter) {
        case 0:
          Kandidaten = this.Daten.Kandidaten
          break
        case 1:
          Kandidaten = this.Daten.Kandidaten.filter((kandidat) => kandidat.Landesliste)
          break
        case 2:
          Kandidaten = this.Daten.Kandidaten.filter((kandidat) => kandidat.Direktwahlkreis)
          break
        case 3:
          Kandidaten = this.Daten.Kandidaten.filter((kandidat) => kandidat.LaVoPraesidium)
          break
      }
      Kandidaten.sort((a, b) => {
        return b.Einigkeit - a.Einigkeit
      })
      return Kandidaten
    }
  },

  methods: {
    isSecure: function () {
      return window.isSecureContext
    },

    loadAnswers: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getAntworten'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.Daten = response.data
        for (const kandidat in this.Daten.Kandidaten) {
          for (const antwort in this.Daten.Antworten) {
            this.Daten.Antworten[antwort].Meinung = this.Daten.Antworten[antwort].Antwort
          }
          if (this.Daten.Kandidaten[kandidat].FotoUrl !== null) {
            this.Daten.Kandidaten[kandidat].Foto = 'https://juliaomat.julia-sachsen.de/Personen/klein/' + this.Daten.Kandidaten[kandidat].FotoUrl
          } else if (this.Daten.Kandidaten[kandidat].FotoKlein === 0) {
            this.Daten.Kandidaten[kandidat].Foto = this.getPersonQuadrat()
          } else {
            this.Daten.Kandidaten[kandidat].Foto = this.getLadenQuadrat()
          }
          this.Daten.Kandidaten[kandidat].Einigkeit = this.uebereinstimmungBerechnen(this.getAntwortenNutzer(), this.getAntwortenKandidat(this.Daten.Kandidaten[kandidat].kId))
        }
        this.Daten.Kandidaten.push({
          Nachname: 'JuliA Sachsen',
          Einigkeit: this.uebereinstimmungBerechnen(this.getAntwortenNutzer(), this.getAntwortenJuliA()),
          Foto: this.getSignet()
        })
      }).then(() => {
        this.Daten.Kandidaten.sort((a, b) => {
          return b.Einigkeit - a.Einigkeit
        })
      }).then(() => {
        for (const kandidat in this.Daten.Kandidaten) {
          if (this.Daten.Kandidaten[kandidat].FotoKlein !== 0 && this.Daten.Kandidaten[kandidat].Nachname !== 'JuliA Sachsen' && this.Daten.Kandidaten[kandidat].FotoUrl === null) {
            this.fotoLaden(this.Daten.Kandidaten[kandidat].kId, this.Daten.Kandidaten[kandidat])
          }
        }
      })
    },

    fotoLaden: function (kId, element) {
      axios.post(this.getFunctionsURL(), {
        function: 'getKandidatFotoKlein',
        kId: kId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        element.Foto = response.data
      })
    },

    getAntwortenKandidat: function (kId) {
      return this.Daten.Antworten.filter((antwort) => antwort.kId === kId)
    },

    getAntwortenJuliA: function () {
      const JuliA = this.Daten.Statements
      for (const antwort in JuliA) {
        JuliA[antwort].Meinung = JuliA[antwort].JuliA
      }
      return JuliA
    },

    getAntwortenNutzer: function () {
      const Antworten = this.$route.params.antworten
      const Statements = this.Daten.Statements.map((x) => {
        return {
          sId: x.sId,
          Wert: 0,
          Meinung: null
        }
      })
      for (const statement in Statements) {
        const a = Antworten.substring(Statements[statement].sId, Statements[statement].sId + 1)
        switch (a) {
          case 'a':
            Statements[statement].Meinung = -1
            Statements[statement].Wert = 1
            break
          case 'b':
            Statements[statement].Meinung = 0
            Statements[statement].Wert = 1
            break
          case 'c':
            Statements[statement].Meinung = 1
            Statements[statement].Wert = 1
            break
          case 'd':
            Statements[statement].Meinung = -1
            Statements[statement].Wert = 2
            break
          case 'e':
            Statements[statement].Meinung = 0
            Statements[statement].Wert = 2
            break
          case 'f':
            Statements[statement].Meinung = 1
            Statements[statement].Wert = 2
            break
          case 'x':
            Statements[statement].Meinung = null
            Statements[statement].Wert = 0
            break
        }
      }
      return Statements
    },

    copyLink: function () {
      navigator.clipboard.writeText(window.location.href)
    }
  },

  mounted: function () {
    this.loadAnswers()
  }
}
</script>

<style scoped>

p {
  margin: 0.5em 0;
}

@media (orientation: landscape) {
  .home {
    font-size: 1.5em;
  }
}

</style>
