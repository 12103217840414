<template>
  <header>
    <router-link to="/">
      <img class="logo" src="./assets/logo.svg" alt="JuliA Sachsen">
    </router-link>
    <nav>
      <router-link to="/">JuliA-O-Mat</router-link><br>
      <router-link to="/kandidaten">Kandidaten</router-link>
    </nav>
  </header>
  <main>
    <router-view/>
    <div id="footer"><a href="https://julia-sachsen.de/impressum/" target="_blank">Impressum</a> | <a href="https://julia-sachsen.de/datenschutzerklaerung/" target="_blank">Datenschutz</a></div>
  </main>
</template>

<style>
:root {
  --magenta: #E6007E;
  --yellow: #FFED00;
  --cyan: #009EE3;
  --softMagenta: #F5B5D2;
  --softYellow: #FFF7B2;
  --softCyan: #A1DAF8;
  --black: #000000;
  --softGray: #CCCCCC;
  --gray: #888888;
  --white: #FFFFFF;
}

@font-face {
  font-family: 'Anybody';
  src: url('./assets/fonts/Anybody-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody Bold';
  src: url('./assets/fonts/Anybody-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody ExtraBold';
  src: url('./assets/fonts/Anybody-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody Light';
  src: url('./assets/fonts/Anybody-Light.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Anybody Light, Calibri, sans-serif;
  color: var(--black);
  background: var(--softYellow);
  line-height: 1.1;
}

h1 {
  font-family: Anybody ExtraBold;
  font-size: 2rem;
  margin: 1.5rem 0;
}
h2, h3, h4 {
  font-family: Anybody;
}

b, em {
  font-family: Anybody Bold;
  font-weight: normal;
}

header {
  background: var(--white);
  font-family: Anybody ExtraBold, Calibri, sans-serif;

  mask-image: url("./assets/header-mask.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: bottom;

  height: max(9vw, 11vh);
  padding-top: max(0.5vw, 1vh);
  padding-bottom: max(3vw, 3vh);
  padding-right: max(3vw, 1vh);
  margin-bottom: min(-3vw, -3vh);

  position: sticky;
  top: 0;

  pointer-events: none;
  z-index: 999;
}

header * {
  pointer-events: auto;
}

a {
  color: var(--magenta);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

nav {
  float: right;
  font-size: max(2.5vw, 3vh);
  text-align: right;
  user-select: none;
}

nav a {
  padding: 0.2em;
  padding-bottom: 0;
  display: inline-block;
}
nav a:first-of-type {
  margin-top: 0;
}
nav a:last-of-type {
  margin-bottom: 0;
}
nav a:hover {
  text-decoration: none;
}

nav a.router-link-active {
  color: var(--yellow);
  background: var(--magenta);
  border-radius: 10px;
}

.logo {
  height: 100%;
  max-width: 50%;
  float: right;
  margin-left: 2vw;
}

main {
  width: calc(100% - 2em);
  padding: 0 1rem 0.5rem 1rem;
  overflow: hidden;
}

button {
  color: var(--magenta);
  background: var(--yellow);
  font-family: Anybody Bold;
  font-size: 1em;
  border: 0;
  border-radius: 0.5em;
  padding: 0.5em;
  cursor: pointer;
}

button:hover {
  color: var(--yellow);
  background: var(--magenta);
}

#footer {
  color: var(--gray);
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem;
  width: 100%;
  user-select: none;
}

#footer a {
  color: var(--gray);
}

@media (orientation: landscape) {
  #titel, #filter {
    display: inline-block;
  }

  #titel {
    max-width: max(calc(100% / 2), 500px);
    min-width: calc(100% / 2);
  }

  #filter {
    min-width: 150px;
    max-width: 25%;
  }
}

@media (orientation: portrait) {
  #titel, #filter {
    width: 100%;
  }
}

#titel, #filter {
  margin-bottom: 0.5rem;
}

#titel {
  font-size: 2rem;
}

#filter {
  font-size: 1.5rem;
  border-radius: 0.5em;
  padding: 0.2em;
}

</style>
