<template>
  <div class="home">
    <div id="Einleitung">
      <img :src="getSmileySticker()" id="stickerOben" />
      <h1>Willkommen zum JuliA-O-Mat!</h1>
      <p>Mit diesem JuliA-O-Mat wollen wir unseren Mitglieder, aber auch allen anderen Interessierten eine Entscheidungs&shy;hilfe für die Kandidaten&shy;aufstellung zur Landtags&shy;wahl 2024 bereit&shy;stellen.</p>
      <p>Wir haben Statements erarbeitet, die unsere Positionen als JuliA Sachsen besonders herausstellen und alle Kandidierenden gebeten sich zu diesen zu positionieren. Die Ergebnisse könnt ihr auf dieser Seite einsehen.</p>
      <img :src="getMegafonSticker()" id="stickerKandidatur" />
      <p>Du kandidierst auch für die Landesliste oder einen Direktwahlkreis für die FDP? Dann melde dich bei uns unter <a href="mailto:juliaomat@julia-sachsen.de">juliaomat@julia-sachsen.de</a> und wir schicken dir das Formular zu!</p>
    </div>
    <div class="Frage" v-for="(statement, index) in Statements" :key="statement.sId" :class="{ hidden: index != fragenId }">
      <p v-if="index > 0" v-on:click="fragenId--" style="cursor: pointer; width: 50%; text-align: left; float: left; margin-top: 2.5em;">❮ Zurück</p>
      <p v-if="index <= 0" style="cursor: pointer; width: 50%; text-align: left; float: left; margin-top: 2.5em;"></p>
      <p v-on:click="meinungAbgeben(null)" style="cursor: pointer; width: 50%; text-align: right; float: left; margin-top: 2.5em;">Frage ignorieren</p>
      <p class="statement"><span v-html="statement.Statement" /></p>
      <div class="wichtung">
        <input type="checkbox" :id="'doppelt'+statement.sId" v-on:input="statement.Wert = 3 - statement.Wert" />
        <label :for="'doppelt'+statement.sId">Frage doppelt gewichten</label>
      </div>
      <div class="meinung">
        <div class="buttonJa" :class="{active: statement.Meinung > 0}" v-on:click="meinungAbgeben(1)">Zustimmung</div>
        <div class="buttonNeutral" :class="{active: statement.Meinung == 0}" v-on:click="meinungAbgeben(0)">neutral</div>
        <div class="buttonNein" :class="{active: statement.Meinung < 0}" v-on:click="meinungAbgeben(-1)">Ablehnung</div>
      </div>
    </div>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'
import axios from 'axios'

export default {

  name: 'HomeView',

  mixins: [Konstanten],

  data () {
    return {
      Statements: [],
      fragenId: 0
    }
  },

  methods: {
    statementsEmpfangen: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getStatementsAktuell'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        for (const statement in response.data) {
          const stm = response.data[statement]
          this.Statements.push({
            sId: stm.sId,
            Statement: stm.Statement,
            Meinung: null,
            Wert: 1,
            JuliA: stm.JuliA
          })
        }
      })
    },

    meinungAbgeben: function (meinung) {
      this.Statements[this.fragenId].Meinung = meinung
      this.fragenId++
      document.getElementById('Einleitung').classList.add('hidden')
      if (this.fragenId >= this.Statements.length) {
        const maxSId = this.Statements.reduce((a, b) => {
          return Math.max(a, b.sId)
        }, -1)
        let Antworten = ''
        for (let i = 0; i <= maxSId; i++) {
          Antworten += 'x'
        }
        for (const statement in this.Statements) {
          if (this.Statements[statement].Meinung !== null && this.Statements[statement].Wert !== null) {
            const wertArray = [['x', 'x', 'x'], ['a', 'b', 'c'], ['d', 'e', 'f']][this.Statements[statement].Wert]
            Antworten = Antworten.substring(0, this.Statements[statement].sId) + wertArray[this.Statements[statement].Meinung + 1] + Antworten.substring(1 + this.Statements[statement].sId, maxSId + 1)
          }
        }
        axios.post(this.getFunctionsURL(), {
          function: 'SitzungSpeichern',
          sitzung: Antworten
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          this.$router.push('/ergebnis/' + Antworten)
        })
      }
    }
  },

  mounted: function () {
    this.statementsEmpfangen()
  }
}
</script>

<style scoped>

p {
  margin: 0.5em 0;
}

@media (orientation: portrait) {
  #stickerOben {
    width: max(7em, 20vw);
    float: right;
    margin-top: 1.5rem;
  }

  #stickerKandidatur {
    height: max(6em, 20vw);
    float: left;
    margin-right: 1em;
  }
}

@media (orientation: landscape) {
  .home {
    font-size: 1.5em;
  }

  #stickerOben {
    width: max(8em, 7vw);
    float: left;
    margin-right: 1em;
  }

  #stickerKandidatur {
    height: max(6em, 7vw);
    float: right;
    display: inline-block;
    margin-left: 1em;
    margin-right: 15vw;
    margin-top: -0.75em;
  }

  h1 {
    display: inline-block;
  }
}

.Frage {
  max-height: 70vh;
  transition: all 0.5s;
  transition-delay: 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Frage::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.Frage.hidden {
  max-height: 0;
  transition-delay: 0.25s;
  overflow-y: hidden;
}

.statement {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  font-family: Anybody;
}

.meinung {
  width: 100%;
}

.buttonJa, .buttonNeutral, .buttonNein {
  text-align: center;
  width: 100%;
  font-family: Anybody;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  border-radius: 0.5em;
}

.buttonJa:hover, .buttonNeutral:hover, .buttonNein:hover {
  color: var(--magenta);
  box-shadow: inset 0 0 0 0.2em var(--cyan);
}

.buttonJa {
  color: var(--softMagenta);
  background: var(--white);
}
.buttonJa.active {
  color: var(--magenta);
  background: var(--white);
  box-shadow: inset 0 0 0 0.2em var(--magenta);
}

.buttonNeutral {
  color: var(--gray);
  background: var(--softGray);
}
.buttonNeutral.active {
  color: var(--black);
  background: var(--gray);
  box-shadow: inset 0 0 0 0.2em var(--magenta);
}

.buttonNein {
  color: var(--white);
  background: var(--softMagenta);
}
.buttonNein.active {
  color: var(--white);
  background: var(--magenta);
  box-shadow: inset 0 0 0 0.2em var(--yellow);
}

#Einleitung {
  max-height: 100vh;
  overflow: hidden;
  transition: all 0.5s;
  transition-delay: 0.25s;
  margin-bottom: -2.5em;
}

#Einleitung.hidden {
  max-height: 0;
  margin-bottom: 0;
}

.wichtung {
  text-align: center;
  margin-bottom: 0.5em;
}
.wichtung input {
  width: auto;
  margin-right: 0.5em;
}
.wichtung input, .wichtung label {
  display: inline;
}

</style>
