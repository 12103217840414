<template>
  <div>
    <h1>Fragebogen</h1>
    <p>Der von dir eingegebene Link/Code ist fehlerhaft oder du hast den Fragebogen bereits abgesendet. Sollte es Probleme geben, melde dich gern bei uns unter <a href="mailto:juliaomat@julia-sachsen.de">juliaomat@julia-sachsen.de</a>.</p>
    <p><i>(Beim Versand einiger Mails ist uns leider eine Ungenauigkeit passiert. Sollte der Link hierher führen, probiere es mit den letzten Zeichen des Links nach dem letzten / als Code.)</i></p>
    <p>Versuche es mit deinem Code hier erneut:</p>
    <input type="text" placeholder="Formular-Code" v-model="FormularCode" v-on:keyup.enter="formularOeffnen" />
    <button v-on:click="formularOeffnen">Formular öffnen</button>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'

export default {
  name: 'KandidatenFormularError',

  mixins: [Konstanten],

  data () {
    return {
      FormularCode: ''
    }
  },

  computed: {

  },

  methods: {
    formularOeffnen: function () {
      this.go('/kandidat/' + this.FormularCode)
    }
  },

  mounted: function () {

  }
}
</script>

<style>
  input, select {
    width: calc(100% - 0.5em);
    max-width: max(calc(100% / 2 - 0.5em), 500px);
    border: 0;
    padding: 0.25em;
    font-family: Anybody;
    font-size: 1.2em;
    margin-bottom: 0.5em;
    display: block;
    background: var(--white);
  }
</style>
