<template>
  <div class="Kandidat">
    <img id="Foto" :src="Foto" v-on:click="FotoZeigen(FotoUrl)" />
    <div id="Name">{{Vorname}} {{Nachname}}</div>
    <div id="Einigkeit">{{(Einigkeit * 100).toFixed(1)}} %</div>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'

export default {

  name: 'ErgebnisKandidat',

  mixins: [Konstanten],

  props: {
    Vorname: String,
    Nachname: String,
    Einigkeit: Number,
    Foto: String,
    FotoUrl: String
  }
}
</script>

<style scoped>

.Kandidat {
  float: left;
  margin-bottom: 1em;
  border-radius: 2em 1em 1em 2em;
  background-image: linear-gradient(90deg, transparent, var(--magenta));
  font-family: Anybody;
  user-select: none;
}

@media (orientation: landscape) {
  .Kandidat {
    width: 49%;
    font-size: 2rem;
  }

  .Kandidat:nth-of-type(2n) {
    margin-left: 1%;
  }
  .Kandidat:nth-of-type(2n+1) {
    margin-right: 1%;
  }
}
@media (orientation: portrait) {
  .Kandidat {
    width: 100%;
    font-size: 1.25rem;
  }
}

#Foto {
  height: 4em;
  width: 4em;
  border-radius: 2em;
  object-fit: cover;
  float: left;
  transition: all 0.5s;
  z-index: 1;
}

.Kandidat:hover #Foto {
  height: 8em;
  width: 8em;
  border-radius: 0.5em;
  margin: -3em -4em -3em 0em;
  z-index: 2;
}

div {
  display: flex;
  align-items: center;
}

#Name {
  height: 4em;
  margin: 0 0.5em;
  width: calc(100% - 4em - 4em - 1em);
  float: left;
}

#Einigkeit {
  height: 4em;
  width: 4em;
  float: left;
  color: var(--white);
}

</style>
