<template>
  <div>
    <img :src="getSmileySticker()" id="stickerOben" />
    <h1 id="titel">Die Kandidaten</h1>
    <select id="filter" v-model="Filter">
      <option :value="0">Alle</option>
      <option :value="1">Listenkandidaten</option>
      <option :value="2">Direktkandidaten</option>
    </select>
    <p v-if="!Login">Um als Mitglied oder Fördermitglied die einzelnen Antworten der Kandidaten mit den Erklärungen einzusehen musst du dich anmelden. Dazu benötigst du deine Mitgliedsnummer, die du beispielsweise auf deinem Mitgliedsausweis oder als Benutzername für OpenSlides findest. Solltest du die Daten nicht finden, melde dich gern unter <a href="mailto:juliaomat@julia-sachsen.de">juliaomat@julia-sachsen.de</a> bei uns.</p>
    <input id="loginInput" type="Number" v-if="!Login" placeholder="Mitgliedsnummer" v-on:keyup.enter="login" v-model="Mitgliedsnummer" />
    <div id="loginButton" v-if="!Login" v-on:click="login">Login</div>
    <div id="roundedCorners">
      <div id="tableScroll">
        <table>
          <tr>
            <th id="cellTopLeft" class="statement"><img :src="getSmileySticker()" id="stickerErsteZelle" /></th>
            <th v-for="kandidat in Kandidaten" :key="kandidat.kId" class="meinung" style="vertical-align: top; padding-bottom: 0;">
              <img class="KandidatenFoto" :src="kandidat.Foto" v-on:click="FotoZeigen(kandidat.FotoUrl)" /><br />
              <span class="FM" v-if="kandidat.Mitgliedschaft === 2">Mitglied<br /></span>
              <span class="FM" v-if="kandidat.Mitgliedschaft === 1">Förder&shy;mitglied<br /></span>
              <span class="Name">{{kandidat.Vorname}} {{kandidat.Nachname}}</span></th>
          </tr>
          <tr>
            <td class="statement" style="border: 0;"></td>
            <td v-for="kandidat in Kandidaten" :key="kandidat.kId" class="meinung" style="vertical-align: top; padding-top: 0;">
              <span class="KV">({{kandidat.Kreisverband}})</span><br />
              <span class="Kandidatur">kandidiert für <em><span v-html="kandidat.Kandidatur.replace('Direktwahlkreis', 'Direkt&shy;wahl&shy;kreis').replace('Wahlkreis', 'Wahl&shy;kreis').replace('Listenplatz', 'Listen&shy;platz').replace('Generalsekretär', 'General&shy;sekretär').replace('Landesvorsitz', 'Landes&shy;vorsitz').replace('Stellvertretend', 'Stell&shy;vertretend').replace('Generalsekretär', 'General&shy;sekretär').replace('Landesschatzmeister', 'Landes&shy;schatz&shy;meister').replace('stellvertretend', 'stell&shy;vertretend').replace('Landesvorstand', 'Landes&shy;vorstand').replace('Direktkandidat', 'Direkt&shy;kandidat')" /></em></span></td>
          </tr>
          <tr v-for="statement in Daten.Statements.filter(() => { return this.Login })" :key="statement.sId" style="user-select: none;">
            <td class="statement"><span v-html="statement.Statement" /><i v-if="statement.Wert === 2"> (x2)</i></td>
            <td class="meinung" v-for="kandidat in Kandidaten" :key="kandidat.kId" v-on:click="erklaerungZeigen(statement.sId, kandidat.kId)" :class="{clickable: erklaerungPruefen(statement.sId, kandidat.kId)}">{{antwortLaden(statement.sId, kandidat.kId)}} <img v-if="erklaerungPruefen(statement.sId, kandidat.kId)" class="click" :src="getClickSticker()" /></td>
          </tr>
          <tr>
            <td class="statement"></td>
            <td class="meinung likeLine" v-for="kandidat in Kandidaten" :key="kandidat.kId">
              <img v-if="uebereinstimmungBerechnen(getAntwortenJuliA(), getAntwortenKandidat(kandidat.kId)) >= 0.8" class="like" :src="getLikeSticker()" />
            </td>
          </tr>
          <tr id="uebereinstimmung">
            <td class="statement">Übereinstimmung<br>mit der JuliA</td>
            <td class="meinung" v-for="kandidat in Kandidaten" :key="kandidat.kId">{{(uebereinstimmungBerechnen(getAntwortenJuliA(), getAntwortenKandidat(kandidat.kId)) * 100).toFixed(1)}}&nbsp;%</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Konstanten from '../scripts/Konstanten'
import axios from 'axios'

export default {
  name: 'KandidatenView',

  mixins: [Konstanten],

  data () {
    return {
      Daten: {
        Kandidaten: [],
        Statements: [],
        Antworten: []
      },
      Filter: 1,
      Login: false,
      Mitgliedsnummer: ''
    }
  },

  computed: {
    Kandidaten: function () {
      switch (this.Filter) {
        case 0:
          return this.Daten.Kandidaten
        case 1:
          return this.Daten.Kandidaten.filter((kandidat) => kandidat.Landesliste)
        case 2:
          return this.Daten.Kandidaten.filter((kandidat) => kandidat.Direktwahlkreis)
        case 3:
          return this.Daten.Kandidaten.filter((kandidat) => kandidat.LaVoPraesidium)
      }
      return this.Daten.Kandidaten
    }
  },

  methods: {
    loadAnswers: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getAntworten'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.getClickSticker()
        this.Daten = response.data
        for (const kandidat in this.Daten.Kandidaten) {
          if (this.Daten.Kandidaten[kandidat].FotoUrl !== null) {
            this.Daten.Kandidaten[kandidat].Foto = 'https://juliaomat.julia-sachsen.de/Personen/klein/' + this.Daten.Kandidaten[kandidat].FotoUrl
          } else if (this.Daten.Kandidaten[kandidat].FotoKlein === 0) {
            this.Daten.Kandidaten[kandidat].Foto = this.getPersonQuadrat()
          } else {
            this.Daten.Kandidaten[kandidat].Foto = this.getLadenQuadrat()
          }
        }
        for (const antwort in this.Daten.Antworten) {
          this.Daten.Antworten[antwort].Meinung = this.Daten.Antworten[antwort].Antwort
        }
      }).then(() => {
        for (const kandidat in this.Daten.Kandidaten) {
          if (this.Daten.Kandidaten[kandidat].FotoKlein !== 0 && this.Daten.Kandidaten[kandidat].FotoUrl === null) {
            this.fotoLaden(this.Daten.Kandidaten[kandidat].kId, kandidat)
          }
        }
      })
    },

    fotoLaden: function (kId, index) {
      axios.post(this.getFunctionsURL(), {
        function: 'getKandidatFotoKlein',
        kId: kId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.Daten.Kandidaten[index].Foto = response.data
      })
    },

    antwortLaden: function (sId, kId) {
      for (const antwort in this.Daten.Antworten) {
        if (this.Daten.Antworten[antwort].kId === kId && this.Daten.Antworten[antwort].sId === sId) {
          switch (this.Daten.Antworten[antwort].Meinung) {
            case -1:
              return 'Ablehnung'
            case 0:
              return 'Neutral'
            case 1:
              return 'Zustimmung'
            case null:
              return '---'
          }
        }
      }
      return '---'
    },

    erklaerungPruefen: function (sId, kId) {
      for (const antwort in this.Daten.Antworten) {
        if (this.Daten.Antworten[antwort].kId === kId && this.Daten.Antworten[antwort].sId === sId) {
          if (this.Daten.Antworten[antwort].Erklärung) return true
        }
      }
      return false
    },

    erklaerungZeigen: function (sId, kId) {
      for (const antwort in this.Daten.Antworten) {
        if (this.Daten.Antworten[antwort].kId === kId && this.Daten.Antworten[antwort].sId === sId) {
          if (this.Daten.Antworten[antwort].Erklärung) alert(this.Daten.Antworten[antwort].Erklärung)
        }
      }
    },

    getAntwortenKandidat: function (kId) {
      return this.Daten.Antworten.filter((antwort) => antwort.kId === kId)
    },

    getAntwortenJuliA: function () {
      const JuliA = this.Daten.Statements
      for (const antwort in JuliA) {
        JuliA[antwort].Meinung = JuliA[antwort].JuliA
      }
      return JuliA
    },

    login: function () {
      if (this.Mitgliedsnummer === '' || this.Mitgliedsnummer === null) return true
      axios.post(this.getFunctionsURL(), {
        function: 'isMitglied',
        Mitgliedsnummer: this.Mitgliedsnummer
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 1) {
          this.Login = true
          localStorage.Mitgliedsnummer = this.Mitgliedsnummer
        } else {
          this.Login = false
          localStorage.removeItem('Mitgliedsnummer')
          alert('Falsche Mitgliedsnummer. Melde dich gern bei uns, wenn du etwas nicht funktioniert hast.')
        }
      })
    }
  },

  mounted: function () {
    this.loadAnswers()
    this.Mitgliedsnummer = localStorage.Mitgliedsnummer || null
    this.login()
  }
}
</script>

<style scoped>
  #tableScroll {
    background: var(--white);
    overflow-x: auto;
    overflow-y: visible;
    width: 100%;
    -ms-overflow-style: thin;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
  }

  #tableScroll::-webkit-scrollbar {
    display: thin; /* Chrome, Safari and Opera */
  }

  #roundedCorners {
    border-radius: 1.5em;
    overflow: hidden;
  }

  @media (orientation: landscape) {
    #roundedCorners, #tableScroll {
      max-height: calc(100vh - max(9vw, 11vh) - max(4vw, 4vh) -  min(-3vw, -3vh) - 6.2rem);
    }
  }

  @media (orientation: portrait) {
    #roundedCorners, #tableScroll {
      max-height: calc(100vh - max(9vw, 11vh) - max(4vw, 4vh) -  min(-3vw, -3vh) - 8rem);
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  @media (orientation: portrait) {
    table {
      font-size: 0.9em;
    }
  }

  .KandidatenFoto {
    height: max(4em, 7vw);
    width: max(4em, 7vw);
    border-radius: 0.5em;
    object-fit: cover;
  }

  td, th {
    padding: 0.5em;
  }

  tr:hover, tr:hover td {
    background: var(--softMagenta);
  }

  th {
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 1;
  }

  td.statement, th.statement {
    min-width: min(33vw, 500px);
    max-width: calc(50vw - 1rem - 4px - 1em);
    text-align: right;
    padding: 0.5em;
  }

  td.statement {
    border-right: var(--magenta) 4px solid;
  }

  td.meinung, th.meinung {
    min-width: min(max(5em, 8vw), 33vw);
    max-width: max(5em, 8vw);
    text-align: center;
    width: auto;
    padding: 5px;
  }

  .statement {
    position: sticky;
    left: 0;
    background: var(--white);
    hyphens: auto;
    z-index: 0;
  }

  #cellTopLeft {
    z-index: 2;
  }

  .Name {
    color: var(--magenta);
    font-size: 1.2em;
    font-family: Anybody;
  }

  .KV {
    font-size: 0.75em;
  }

  .FM {
    font-size: 0.8em;
    color: var(--softMagenta);
  }

  #uebereinstimmung {
    font-weight: bold;
  }

  #uebereinstimmung .meinung {
    font-size: 1.5em;
  }

  @media (orientation: landscape) {
    #stickerOben {
      display: none;
    }

    #stickerErsteZelle {
        height: min(8em, 14vw);
        float: right;
        margin-bottom: -10px;
        margin-right: 3em;
        transform: scaleX(-1);
    }
  }

  @media (orientation: portrait) {
    #stickerOben {
      height: 4.5em;
      float: right;
      margin-bottom: -4.5em;
      margin-right: 1em;
      pointer-events: none;
    }

    #stickerErsteZelle {
      display: none;
    }
  }

  .click {
    height: 1.5em;
    margin-bottom: -0.5em;
  }

  .clickable {
    cursor: pointer;
  }

  .like {
    width: 80%;
  }

  .likeLine {
    padding: 0;
  }

  #loginInput, #loginButton {
    display: inline-block;
    font-size: 1.25rem;
    padding: 0.25em;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  #loginInput {
    max-width: calc(80% - 1.5em);
  }

  #loginButton {
    color: var(--magenta);
    background: var(--yellow);
    font-family: Anybody;
    width: 20%;
    margin-left: 0.5em;
    text-align: center;
    cursor: pointer;
  }

  #loginButton:hover {
    color: var(--yellow);
    background: var(--magenta);
  }
</style>
